import * as React from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SmsIcon from '@mui/icons-material/Sms'
import AbcIcon from '@mui/icons-material/Abc'
import MessageIcon from '@mui/icons-material/Message';
import HubIcon from '@mui/icons-material/Hub';
import TaskIcon from '@mui/icons-material/Task';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import TagIcon from '@mui/icons-material/Tag';

import { DialogContext } from '../context/DialogContext.js'

export default function AddButtonLife() {
  const { dialogStates, openDialog, closeDialog } = React.useContext(DialogContext);

  const actions = [
    { icon: <QuestionAnswerIcon />, name: 'Inspiration', onClick: () => openDialog('dialogAddInspiration') },
    // { icon: <DirectionsRunIcon />, name: 'Action' },
    // { icon: <SelfImprovementIcon />, name: 'Self Improvement' },
    { icon: <PostAddIcon />, name: 'Project', onClick: () => openDialog('dialogAddProject') },
    // { icon: <FilterDramaIcon />, name: 'Thoughts', onClick: () => openDialog('dialogAddInspiration') },
    // { icon: <PsychologyAltIcon />, name: 'Question', onClick: () => openDialog('dialogAddQuestion') },
    { icon: <TagIcon />, name: 'Consumption', onClick: () => openDialog('dialogAddConsumption') },
    { icon: <TaskIcon />, name: 'Task', onClick: () => openDialog('dialogAddTask') },
  ]
  
  return (
      <SpeedDial ariaLabel="SpeedDialLife" icon={<HubIcon />}>
        {actions.map((action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.onClick} />
        ))}
      </SpeedDial>
  )
}
