import React, { useState, useCallback } from 'react'
import { TextField, Button, Typography, Box, CircularProgress, Slider } from '@mui/material'
import styles from './ChatbotFullView.module.scss'
import { speak } from '../speech/speak'
import voices from '../speech/voices'

export default function LocalChatbot({ voiceSpeed }) {
  const [input, setInput] = useState('')
  const [response, setResponse] = useState('')
  const [loading, setLoading] = useState(false)
  const [maxTokens, setMaxTokens] = useState(16) // Default max_tokens value

  const handleSend = async () => {
    if (!input.trim()) return

    setLoading(true)
    setResponse('')

    try {
      const res = await fetch('http://81.84.225.49:8859/v1/completions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          model: 'llama3',
          prompt: input,
          max_tokens: maxTokens, // Use the state variable
          temperature: 0.7
        })
      })

      if (!res.ok) {
        throw new Error(`Server error: ${res.status} ${res.statusText}`)
      }

      const data = await res.json()
      if (data.choices && data.choices.length > 0) {
        setResponse(data.choices[0].text.trim())
      } else {
        setResponse('No response from the model.')
      }
    } catch (error) {
      console.error('Fetch error:', error)
      setResponse(`Error: ${error.message}`)
    }

    setLoading(false)
  }

  const handleClick = useCallback(() => {
    speak(response, voices['por'], voiceSpeed)
  }, [response, voiceSpeed])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 3 }} className={styles.translationFullView}>
      <Typography variant="h5">Local Chatbot</Typography>

      {/* Text Input */}
      <TextField
        label="Type your message"
        variant="outlined"
        fullWidth
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
      />

      {/* Slider for max_tokens */}
      <Box sx={{ width: '80%' }}>
        <Typography gutterBottom>Max Tokens: {maxTokens}</Typography>
        <Slider value={maxTokens} onChange={(e, newValue) => setMaxTokens(newValue)} step={1} min={10} max={50} valueLabelDisplay="auto" />
      </Box>

      {/* Send Button */}
      <Button variant="contained" color="primary" onClick={handleSend} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Send'}
      </Button>

      {/* Response Display */}
      {response && (
        <Typography variant="body1" sx={{ mt: 2, p: 2, bgcolor: '#f1f1f1', borderRadius: 1 }} onClick={handleClick}>
          {response}
        </Typography>
      )}
    </Box>
  )
}
