import * as React from 'react'
import { useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import './InspirationFullView.css'
import { useGSAP } from '@gsap/react'
import DeviceContext from '../context/DeviceContext'
import styles from './KanBanView.module.scss'

gsap.registerPlugin(SplitText) // Make sure the SplitText plugin is registered

export default function KanbanView({ kanbanData, isKanbanViewVisible, setIsKanbanViewVisible }) {
  const inspirationRef = useRef(null) // Ref to target the inspiration text
  const { isMobile } = useContext(DeviceContext)

  useGSAP(() => {}, []) // Re-run animation if the inspiration text changes

  // Handle key presses for desktop
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isMobile && isKanbanViewVisible) {
        if (event.key === 'e') {
          setIsKanbanViewVisible(false)
        } else {
        }
      }
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isMobile, isKanbanViewVisible])

  return (
    <div className={styles.kanbanFullView}>
      <div className={styles.kanbanView}>#Honk2025</div>
    </div>
  )
}
