// Import required components and hooks
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { DialogContext } from '../context/DialogContext.js'
import UserContext from '../context/UserContext.js'
import { useMutation, gql } from '@apollo/client'

// ✅ Updated GraphQL Mutation: Removed `labels`, added `project`
const ADD_TASK_MUTATION = gql`
  mutation AddTask(
    $title: String!
    $type: String!
    $description: String
    $position: String!
    $priority: String!
    $assignee: String
    $timeBudget: Float
    $timeSpent: Float
    $dueDate: DateTime!
    $username: String!
    $project: String # ✅ Added project field
  ) {
    addTask(
      input: {
        title: $title
        type: $type
        description: $description
        position: $position
        priority: $priority
        assignee: $assignee
        timeBudget: $timeBudget
        timeSpent: $timeSpent
        dueDate: $dueDate
        username: $username
        project: $project # ✅ Ensure project is included in the request
      }
    ) {
      identity
      elementId
      properties {
        title
        type
        description
        position
        priority
        assignee
        timeBudget
        timeSpent
        dueDate
        username
        project # ✅ Include project in response
      }
    }
  }
`

function AddTaskDialogue() {
  const { dialogStates, closeDialog } = React.useContext(DialogContext)
  const [title, setTitle] = useState('')
  const [type, setType] = useState('Coding')
  const [description, setDescription] = useState('')
  const [position, setPosition] = useState('Backlog')
  const [priority, setPriority] = useState('Low')
  const [assignee, setAssignee] = useState('')
  const [timeBudget, setTimeBudget] = useState('')
  const [timeSpent, setTimeSpent] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [project, setProject] = useState('') // ✅ Added project state
  const { username } = React.useContext(UserContext) // Ensure username is sent

  const [addTask, { loading }] = useMutation(ADD_TASK_MUTATION)

  const handleClose = () => {
    closeDialog('dialogAddTask')
  }

  const handleSave = () => {
    addTask({
      variables: {
        title,
        type,
        description,
        position,
        priority,
        assignee,
        timeBudget: timeBudget ? parseFloat(timeBudget) : null,
        timeSpent: timeSpent ? parseFloat(timeSpent) : null,
        dueDate,
        username, // Ensure username is included
        project: project ? project.trim() : null // ✅ Ensure project is included or null
      }
    })
    handleClose()
  }

  return (
    <Dialog open={dialogStates['dialogAddTask']} onClose={handleClose}>
      <DialogTitle>Add a New Task</DialogTitle>
      <DialogContent>
        <TextField label="Title" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} margin="dense" />
        <TextField select label="Type" fullWidth value={type} onChange={(e) => setType(e.target.value)} margin="dense">
          <MenuItem value="Design">Design</MenuItem>
          <MenuItem value="3D">3D</MenuItem>
          <MenuItem value="Coding">Coding</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="Learning">Learning</MenuItem>
        </TextField>
        <TextField label="Description" fullWidth multiline rows={3} value={description} onChange={(e) => setDescription(e.target.value)} margin="dense" />
        <TextField select label="Position" fullWidth value={position} onChange={(e) => setPosition(e.target.value)} margin="dense">
          <MenuItem value="Backlog">Backlog</MenuItem>
          <MenuItem value="ToDo">To Do</MenuItem>
          <MenuItem value="InProgress">In Progress</MenuItem>
          <MenuItem value="Done">Done</MenuItem>
          <MenuItem value="Cancelled">Cancelled</MenuItem>
        </TextField>
        <TextField select label="Priority" fullWidth value={priority} onChange={(e) => setPriority(e.target.value)} margin="dense">
          <MenuItem value="Low">Low</MenuItem>
          <MenuItem value="Med">Medium</MenuItem>
          <MenuItem value="High">High</MenuItem>
          <MenuItem value="Urgent">Urgent</MenuItem>
        </TextField>
        <TextField label="Assignee" fullWidth value={assignee} onChange={(e) => setAssignee(e.target.value)} margin="dense" />

        {/* ✅ Added Project Field */}
        <TextField label="Project (Optional)" fullWidth value={project} onChange={(e) => setProject(e.target.value)} margin="dense" />

        <TextField label="Time Budget (hours)" type="number" fullWidth value={timeBudget} onChange={(e) => setTimeBudget(e.target.value)} margin="dense" />
        <TextField label="Time Spent (hours)" type="number" fullWidth value={timeSpent} onChange={(e) => setTimeSpent(e.target.value)} margin="dense" />

        <TextField
          label="Due Date"
          type="datetime-local"
          fullWidth
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          margin="dense"
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddTaskDialogue
